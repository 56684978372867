
import { reactive, onMounted } from 'vue'
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import axios from "@/plugin/vue-axios";

import ReumTitle from '@/components/ReumLib/layout/ReumTitle.vue'
import ReumPaging from '@/components/ReumLib/UI/ReumPaging.vue'
import ReumPagesize from '@/components/ReumLib/UI/ReumPagesize.vue'
import ReumDatePicker from '@/components/ReumLib/UI/ReumDatePicker.vue'
import ReumLoading from '@/components/ReumLib/UI/ReumLoading.vue'


export default {
    name: 'Home',
    components: {
        ReumTitle,
        ReumPaging,
        ReumPagesize,
        ReumDatePicker,
        ReumLoading
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const toast = useToast();


        const comp = reactive({
            loading:false,
            page:1,
            total: 100,
            pagesize: 50,
            search: {
                status: "",
                datefield: "created_at",
                sdate: "",
                edate: "",
                text : ""
            },
            list:[],
            setPagesize: (val) => {
                console.info("FF", val);
                comp.page = 1;
                comp.total = 0;
                store.commit("setPagesize", val);
                comp.getList();
            },
            add: () => {
                router.push({path: "/ExomeCapture/new"});
            },
            showInfo: (itm) => {
                router.push({path: "/ExomeCapture/" + itm.id});
            },
            dateToString(pdate) {
                return (pdate == null?"":[pdate.getFullYear(),((pdate.getMonth() + 1)>9 ? '' : '0') + (pdate.getMonth() + 1),(pdate.getDate()>9 ? '' : '0') + pdate.getDate()].join(''));
            },
            getExcel: () => {
                let params = {
                    page: comp.page,
                    pagesize: comp.pagesize,
                    ...comp.search
                }
                axios({url:"/api/ExomeCapture/exceldown", method:'GET', responseType: 'blob', params: params}).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let today = new Date();
                    link.setAttribute('download', 'DNAPrep'+comp.dateToString(today) +'.xlsx');
                    document.body.appendChild(link);
                    link.click();
                });

            },
            getList: () => {
                comp.list = [];
                let params = {
                    page: comp.page,
                    pagesize: comp.pagesize,
                    ...comp.search
                }
                axios.get("/api/ExomeCapture/list", {params : params}).then((res) => {
                    if(res.data.err == 0) {
                        comp.list = res.data.list;
                        comp.total = res.data.total;
                    } else {
                        toast.error(res.data.err_msg);
                    }
                });

            },
        });
        onMounted(() => {
            comp.pagesize = store.state.pagesize;
            comp.getList();
        });
        return {
            comp
        };
    },
};
